import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, MenuItem, Menu, Fade, IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from '../ManageUsers.styles';
import DeleteModal from '../DeleteModal';
import ResetPasswordModal from '../ResetPassword';
import UpdateAddUser from '../UpdateAddUser';
import { isMultipleCampusAdmin } from '../../../common/utils';

class User extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.any.isRequired,
    user: PropTypes.object.isRequired,
    schoolList: PropTypes.object,
    isInvitedUser: PropTypes.bool,
    onActivateAccount: PropTypes.func.isRequired,
    onDeactivateAccount: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired,
    onCancelInvite: PropTypes.func.isRequired,
    onResendInvite: PropTypes.func.isRequired,
    firebaseId: PropTypes.string.isRequired,
    profile: PropTypes.object.isRequired,
  };

  state = {
    anchorEl: null,
    isDeleteModal: false,
    isChangePasswordModal: false,
    isUpdateModal: false,
  };

  handleOpenModal = (modal) => {
    this.handleMenuClose();
    if (modal === 'delete') {
      this.setState({ isDeleteModal: true });
    } else if (modal === 'password') {
      this.setState({ isChangePasswordModal: true });
    } else if (modal === 'update') {
      this.setState({ isUpdateModal: true });
    }
  };

  handleCloseModal = () => {
    this.setState({
      isChangePasswordModal: false,
      isDeleteModal: false,
      isUpdateModal: false,
    });
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleResendInvite = () => {
    const { onResendInvite, firebaseId } = this.props;
    this.handleMenuClose();
    onResendInvite(firebaseId);
  };

  getCampusDistrictNumber = () => {
    const { user, schoolList } = this.props;
    const num = user.role === 'campusAdmin' ? user.campusNumber : user.districtNumber;
    if (isMultipleCampusAdmin(user)) {
      return 'Multiple Campus Admin';
    }
    if (schoolList && num && schoolList[num]) {
      return schoolList[num].name;
    }
    return num;
  };

  getStatus = () => {
    const { user, isInvitedUser } = this.props;
    if (isInvitedUser) {
      return 'Invited';
    }
    return user.deactivated ? 'Deactivated' : 'Active';
  };

  renderEditMenu = (options) => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleMenuClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu id="fade-menu" anchorEl={anchorEl} open={open} onClose={this.handleMenuClose} TransitionComponent={Fade}>
          {options.map((option, index) => (
            <MenuItem key={index} onClick={option.modal ? () => option.onClick(option.modal) : option.onClick}>
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  render() {
    const {
      user,
      classes,
      isInvitedUser,
      onActivateAccount,
      onDeactivateAccount,
      onCancelInvite,
      firebaseId,
      onResetPassword,
      profile,
    } = this.props;
    const { isDeleteModal, isChangePasswordModal, isUpdateModal } = this.state;
    let editOptions;
    if (isInvitedUser) {
      editOptions = [
        {
          name: 'Resend Invitation',
          onClick: this.handleResendInvite,
        },
        {
          name: 'Cancel Invitation',
          onClick: this.handleOpenModal,
          modal: 'delete',
        },
      ];
    } else {
      editOptions = [
        {
          name: 'Edit Profile',
          onClick: this.handleOpenModal,
          modal: 'update',
        },
        {
          name: 'Reset Password',
          onClick: this.handleOpenModal,
          modal: 'password',
        },
        {
          name: user.deactivated ? 'Activate Account' : 'Deactivate Account/Revoke Access',
          onClick: this.handleOpenModal,
          modal: 'delete',
        },
      ];
    }
    const role = `${
      user.role === 'campusAdmin' ? 'Campus Admin' : 'District Admin'
    } (${this.getCampusDistrictNumber()})`;
    const userForTable = {
      ...user,
      role,
    };
    const tableHeaders = ['displayName', 'emailAddress', 'role', 'phoneNumber', 'status', 'lastLogin'];
    return (
      <Grid container direction="row" justify="space-between" alignItems="center" item>
        {isUpdateModal && (
          <UpdateAddUser firebaseId={firebaseId} user={user} handleUpdateClose={this.handleCloseModal} />
        )}
        {isDeleteModal && (
          <DeleteModal
            profile={profile}
            user={user}
            isInvitedUser={isInvitedUser}
            onActivateAccount={onActivateAccount}
            onDeactivateAccount={onDeactivateAccount}
            onCancelInvite={onCancelInvite}
            firebaseId={firebaseId}
            handleDeleteClose={this.handleCloseModal}
          />
        )}
        {isChangePasswordModal && (
          <ResetPasswordModal
            user={user}
            firebaseId={firebaseId}
            onResetPassword={onResetPassword}
            handleChangePasswordClose={this.handleCloseModal}
          />
        )}
        {tableHeaders.map((header, index) => (
          <div
            key={header}
            className={classNames(classes.cell, {
              [classes.smallCell]: index === 3 || index === 4,
              [classes.textCell]: index !== 3 && index !== 4,
            })}
            style={{
              background: index % 2 ? 'rgba(255,255,255,0.0)' : 'rgba(255,255,255,0.4)',
            }}
          >
            {header === 'status' ? this.getStatus() : userForTable[header] || '-'}
          </div>
        ))}
        <div className={classNames(classes.cell, classes.iconCell)} style={{ background: 'rgba(255,255,255,0.0)' }}>
          {this.renderEditMenu(editOptions)}
        </div>
      </Grid>
    );
  }
}

export default withStyles(styles)(User);
