const styles = (theme) => ({
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '12px',
    alignSelf: 'center',
    height: 60,
    flexShrink: 0,
    zIndex: 12,
    color: theme.palette.primary.main,
    borderBottom: '1px solid rgba(115, 115, 115, 0.15)',
  },
  iconCell: {
    width: '5%',
    background: '#effdff',
  },
  textCell: {
    width: '20%',
  },
  smallCell: {
    width: '7%',
  },
  headerCell: {
    fontSize: '15px',
  },
  modal: {
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(175, 228, 236, 0.65) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  smallModal: {
    width: theme.spacing.unit * 50,
  },
  passwordStrengthContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 100,
  },
  statusMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1.5rem',
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: 10,
  },
  success: {
    color: theme.palette.success.main,
    marginLeft: 10,
  },
  inputField: {
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

export default styles;
