import firebase from 'firebase';
export const signUp = firebase.functions().httpsCallable('signUp');
export const getSignUpCodeInfo = firebase.functions().httpsCallable('getSignUpCodeInfo');
export const updateUserLogin = async (uid) =>
  // Update lastLogin timestamp in Firebase Realtime Database
  await firebase
    .database()
    .ref(`newDatabase/users/${uid}/lastLogin`) // Directly update lastLogin only
    .set(new Date().toISOString())
    .then(() => console.log('Last login updated successfully'))
    .catch((error) => console.error('Error updating last login:', error));

export default 'auth.api.js';
